export const SET_DELIVERY_SERVICES = 'SET_DELIVERY_SERVICES';
export const SET_SELLER_LIST = 'SET_SELLER_LIST';

export interface DeliveryService {
  key: string,
  title: string,
  icon: string,
  rating: number,
}

export type seller = {
  sellerId: number,
  title: string,
  nds: boolean
}

export interface SetDeliveryServices {
  type: typeof SET_DELIVERY_SERVICES,
  deliveryServices: Array<DeliveryService>
}

export interface SetSellerList {
  type: typeof SET_SELLER_LIST,
  sellers: Array<seller>,
}

export type ReferenceActions = SetDeliveryServices | SetSellerList
