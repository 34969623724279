import {
    Act,
    ActOrder,
    SET_ACTS_LIST,
    SET_ACTS_COUNT,
    SET_CURRENT_ACT,
    SET_CURRENT_ACT_ORDERS_LIST,
    SET_CURRENT_ACT_ORDERS_COUNT,
    orderActions,
} from './types';

export interface OrderState {
  actsList: Act[],
  actsCount: number,
  currentAct: Act | null,
  currentActOrdersList: ActOrder[],
  currentActOrdersCount: number,
}

const initialState: OrderState = {
  actsList: [],
  actsCount: 0,
  currentAct: null,
  currentActOrdersList: [],
  currentActOrdersCount: 0,
};

export default function (state = initialState, action: orderActions | any): OrderState {
  switch (action.type) {
    case SET_ACTS_LIST:
      return { ...state, actsList: action.acts };
    case SET_ACTS_COUNT:
      return { ...state, actsCount: action.count };
    case SET_CURRENT_ACT:
      return { ...state, currentAct: action.act };
    case SET_CURRENT_ACT_ORDERS_LIST:
      return { ...state, currentActOrdersList: action.orders };
    case SET_CURRENT_ACT_ORDERS_COUNT:
      return { ...state, currentActOrdersCount: action.count };
    default:
      return state;
  }
}
