import React, { Component } from 'react';
import { connect } from 'react-redux'
import ActTable from '../components/ActTable';
import List from '../../../customComponents/List';
import { withQueryParams } from '../../../helpers';
import { loadActs } from '../action-creators';

const QUERY_PARAMS_CONFIG = {
  page: { type: 'pageNumber' },
}


class ActListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  setLoading = (loading) => this.setState({ loading });

  componentWillMount() {
    this.loadActs()
  }

  loadActs = () => {
    const { params: { page } } = this.props;
    this.setLoading(true);
    this.props.loadActs(page).then(() => this.setLoading(false), () => this.setLoading(false));
  };

  handlePageChange = (page) => this.props.setFieldValue({ page }, this.loadOrders);

  render () {
    const { params: { page }, actsCount } = this.props;
    const { loading } = this.state;
    return (
      <List
        title="Акты"
        totalCount={actsCount}
        currentPage={page}
        handlePageChange={this.handlePageChange}
        withSearch={false}
      >
        <ActTable loading={loading} />
      </List>
    )
  }
}

const connectStore = connect(
  ({ acts: { actsCount } }) => ({ actsCount }),
  { loadActs },
);

export default connectStore(withQueryParams(QUERY_PARAMS_CONFIG)(ActListView));
