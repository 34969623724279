import {
  Order,
  OrderDetail,
  Tracking,
  SET_ORDERS_LIST,
  SET_ORDERS_COUNT,
  RESET_ORDERS_LIST,
  SET_CURRENT_ORDER,
  RESET_CURRENT_ORDER,
  SET_CURRENT_ORDER_TRACKING_LIST,
  SET_CURRENT_ORDER_TRACKING_COUNT,
  SetOrdersList,
  SetOrdersCount,
  ResetOrdersList,
  SetCurrentOrder,
  ResetCurrentOrder,
  SetCurrentOrderTrackingList,
  SetCurrentOrderTrackingCount,
} from './types';
import { ThunkAction } from '../../utils/types';

export const setOrdersList = (orders: Array<Order>): SetOrdersList => ({
  type: SET_ORDERS_LIST,
  orders,
});

export const setOrdersCount = (count: number): SetOrdersCount => ({
  type: SET_ORDERS_COUNT,
  count,
});

export const resetOrdersList = (): ResetOrdersList => ({
  type: RESET_ORDERS_LIST,
});

export const setCurrentOrder = (order: OrderDetail): SetCurrentOrder => ({
  type: SET_CURRENT_ORDER,
  order,
});

export const setCurrentOrderTrackingList = (tracking: Tracking[]): SetCurrentOrderTrackingList => ({
  type: SET_CURRENT_ORDER_TRACKING_LIST,
  tracking,
});

export const setCurrentOrderTrackingCount = (count: number): SetCurrentOrderTrackingCount => ({
  type: SET_CURRENT_ORDER_TRACKING_COUNT,
  count,
});

export const resetCurrentOrder = (): ResetCurrentOrder => ({
  type: RESET_CURRENT_ORDER,
});

export const loadOrders = (page = 1): ThunkAction => (dispatch, getState, http) => {
  const params: any = { limit: 25, skip: page * 25 - 25 };
  return http.get('/api/v1/shop/order/list/', params).then(
    (response: any) => {
      const { items, count } = response;
      dispatch(setOrdersList(items || []));
      dispatch(setOrdersCount(count));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadOrderDetail = (orderId: string): ThunkAction => (dispatch, getState, http) => {
  return http.get(`/api/v1/shop/order/${orderId}/detail/`).then(
    (response: any) => {
      dispatch(setCurrentOrder(response));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};


export const loadTracking = (plsWaybillNumber: string, page = 1, limit = 10): ThunkAction =>
  (dispatch, getState, http) => {
    return http.get('/api/v1/shop/order/tracking/list/', { page, limit, pls_waybill_number: plsWaybillNumber }).then(
      (response: {count: number, tracking: Array<Tracking>}) => {
        dispatch(setCurrentOrderTrackingList(response.tracking));
        dispatch(setCurrentOrderTrackingCount(response.count));
        return Promise.resolve(response);
      },
    );
  }

