import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const { SubMenu } = Menu;

function HorizontalNav() {
  const history = useHistory();
  const menu = [
    {
      title: 'Заказы',
      to: '/orders',
    },
    {
      title: 'Выплаты',
      to: '/payments',
    },
    {
      title: 'Акты',
      to: '/acts',
    },
  ];

  const renderMenu = useCallback(
    (item) => {
      if (item.submenu) {
        return (
          <SubMenu title={item.title} className="gx-menu-horizontal" onTitleClick={() => history.push(item.submenu[0].to)}>
            {item.submenu.map((subitem) => renderMenu(subitem))}
          </SubMenu>
        );
      }
      return (
        <Menu.Item className="ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal">
          <Link className="ant-menu-submenu-title" to={item.to}>
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
      );
    }, [menu],
  );


  return (
    <Menu
      className="ant-menu ant-menu-light ant-menu-root ant-menu-horizontal"
      mode="horizontal"
    >
      {menu.map((item) => renderMenu(item))}
    </Menu>


  );
}

export default HorizontalNav;
