import React from 'react';
import FormItem from 'antd/es/form/FormItem';
import Select from 'antd/es/select';
import get from 'lodash/get';
import set from 'lodash/set';

const { Option } = Select;

export const CreateAntField = (AntComponent) => ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  type,
  getError,
  errorName,
  children,
  className,
  componentClassName,
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = form.submitCount > 0;
  let hasError = null;
  if (typeof getError === 'function') {
    hasError = getError();
  } else {
    hasError = get(form.errors, field.name) || get(form.errors, errorName);
  }
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  const onInputChange = ({ target: { value } }) => {
    const err = { ...form.errors };
    if (get(err, field.name)) {
      set(err, field.name, undefined);
      form.setErrors(err);
    };
    if (errorName) {
      set(err, errorName, undefined);
      form.setErrors(err);
    }
    form.setFieldValue(field.name, value);
    if (typeof props.onChange === 'function') props.onChange(value);
  };
  const onChange = (value) => {
    const err = { ...form.errors };
    if (get(err, field.name)) {
      set(err, field.name, undefined);
      form.setErrors(err);
    };
    if (errorName) {
      set(err, errorName, undefined);
      form.setErrors(err);
    }
    form.setFieldValue(field.name, value);
    if (typeof props.onChange === 'function') props.onChange(value);
  };
  const onBlur = () => form.setFieldTouched(field.name, true);
  return (
    <FormItem
      label={label}
      hasFeedback={
        !!((hasFeedback && submitted) || (hasFeedback && touched))
      }
      help={submittedError || touchedError ? hasError : false}
      validateStatus={submittedError || touchedError ? 'error' : 'success'}
      wrapperCol={{ span: 24 }}
      labelCol={{ xs: 24 }}
      labelAlign="left"
      className={className}
    >
      <AntComponent
        {...field}
        {...props}
        onBlur={onBlur}
        onChange={type ? onInputChange : onChange}
        type={type}
        className={componentClassName}
      >
        {selectOptions
        && selectOptions.map(({ key, title, value }) =>
          value ?
            <Option key={key} value={value}>{title}</Option> :
            <Option key={key}>{title}</Option>
        )}
      </AntComponent>
      {children}
    </FormItem>
  );
};

export default CreateAntField;
