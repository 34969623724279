import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';


const OrderDetail = React.lazy(() => import('../components/OrderDetail'));
function OrderDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <OrderDetail {...props} />
    </Suspense>
  );
}

export default OrderDetailView;
