import {
  Payment,
  SET_PAYMENTS_LIST,
  SET_PAYMENTS_COUNT,
  SET_CURRENT_PAYMENT,
  RESET_CURRENT_PAYMENT,
  SET_CURRENT_PAYMENT_ORDERS_LIST,
  SetPaymentsList,
  SetPaymentsCount,
  SetCurrentPayment,
  ResetCurrentPayment,
  SetCurrentPaymentOrdersList,
} from './types';
import { ThunkAction } from '../../utils/types';

export const setPaymentsList = (payments: Array<Payment>): SetPaymentsList => ({
  type: SET_PAYMENTS_LIST,
  payments,
});

export const setPaymentsCount = (count: number): SetPaymentsCount => ({
  type: SET_PAYMENTS_COUNT,
  count,
});

export const setCurrentPayment = (payment: Payment): SetCurrentPayment => ({
  type: SET_CURRENT_PAYMENT,
  payment,
});

export const resetCurrentPayment = (): ResetCurrentPayment => ({
  type: RESET_CURRENT_PAYMENT,
});

export const setCurrentPaymentOrdersList = (paymentOrders: any): SetCurrentPaymentOrdersList => ({
  type: SET_CURRENT_PAYMENT_ORDERS_LIST,
  paymentOrders,
});


export const loadPayments = (page = 1): ThunkAction => (dispatch, getState, http) => {
  const params: any = { limit: 25, skip: page * 25 - 25 };
  return http.get('/api/v1/shop/payment/list/', params).then(
    (response: any) => {
      const { items, count } = response;
      dispatch(setPaymentsList(items || []));
      dispatch(setPaymentsCount(count));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadPaymentDetail = (paymentId: string): ThunkAction => (dispatch, getState, http) => {
  return http.get(`/api/v1/shop/payment/${paymentId}/detail/`).then(
    (response: any) => {
      dispatch(setCurrentPayment(response));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const getCurrentPaymentOrders = (paymentId: string | number): ThunkAction => (dispatch, getState, http) => {
  return http.get('/api/v1/shop/payment/orders/list/', { payment_id: paymentId }).then(
    (response: any) => {
      const { items, count } = response;
      dispatch(setCurrentPaymentOrdersList(items));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};


export const setPaymentNumber = (payment_id: string | number, number: string): ThunkAction =>
  (dispatch, getState, http) => {
    return http.post('/api/v1/shop/payment/number/set/', { payment_id, number }).then(
      (response: any) => {
        return Promise.resolve(response);
      },
      (reject: any) => Promise.reject(reject),
    );
  };
