import React from 'react';
import { Col, Row } from 'antd';
import SearchBox from './SearchBox';
import SimplePagination from 'customComponents/SimplePagination';
import ListModeSwitch from './ListModeSwitch';

function List({
  children,
  title,

  searchValue='',
  searchPlaceholder='',
  onSearchChange=null,

  pageSize = 25,
  currentPage = 1,
  totalCount = null,
  handlePageChange = null,

  withFavorites = false,
  filters = [],
  tags = [],
  loading = false,
  addon = null,

  withGraphMode = false,
  onChartClick = null,
}) {
  return (
    <>
      <Row>
        <Col
            lg={withGraphMode ? (addon ? 10 : 14) : (addon ? 13 : 17)}
            md={withGraphMode ? (addon ? 6 : 11) : (addon ? 10 : 15)}
            sm={addon ? 12 : 24}
            xs={addon ? 12 : 24}
        >
          <div className="title h1"><p>{title}</p></div>
        </Col>
        {addon && (
          <Col lg={4} md={5} sm={12} xs={12}>
            {addon}
          </Col>
        )}
        {!!handlePageChange && !!totalCount && (
          <Col lg={7} md={9} sm={withGraphMode ? 18 : 24} xs={withGraphMode ? 16 : 24}>
            <SimplePagination
              current={currentPage}
              pageSize={pageSize}
              total={totalCount}
              loading={loading}
              onChange={handlePageChange}
            />
          </Col>
        )}
        {withGraphMode && (
          <Col lg={3} md={4} sm={6} xs={8}>
            <div className="gx-float-right">
              <ListModeSwitch onChartClick={onChartClick} />
            </div>
          </Col>
        )}
      </Row>
      {!!onSearchChange && (
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <SearchBox
              searchValue={searchValue}
              searchPlaceholder={searchPlaceholder}
              onSearchChange={onSearchChange}
              filters={filters}
              tags={tags}
              withFavorites={withFavorites}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          {children}
        </Col>
      </Row>
    </>
  );
}

export default List;
