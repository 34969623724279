import moment from 'moment';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Card, Table, Menu, Dropdown, Button } from 'antd';
import { USER_DATE_FORMAT } from '../../../utils/constants';
import { getFileUrl } from '../../../utils/file';


function ActTable({ loading }) {
  const history = useHistory();
  const { actsList } = useSelector((state) => state.acts);
  const { sellers } = useSelector((state) => state.reference);

  const handleRowClick = useCallback((record) => {
    history.push(`/acts/${record.id}`);
  }, []);

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Продавец',
      dataIndex: 'sellerId',
      key: 'sellerId',
      render: (text) => {
        const seller = sellers.find((_) => _.sellerId === text);
        return <span>{seller ? seller.title : text}</span>;
      },
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{moment(text).format(USER_DATE_FORMAT)}</span>,
    },
    {
      className: 'gx-text-left',
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Файлы',
      dataIndex: '',
      key: '',
      render: (text, record) => record.actFileUrl ? getFilesDropdown(record) : '',
    },
  ];

  const getFilesDropdown = (record) => {
    const act = getFileUrl(record.actFileUrl);


    const menu = (
      <Menu>
        {act && <Menu.Item key="2"><a href={act} target="_blank" rel="noopener noreferrer">Скачать акт</a></Menu.Item>}
      </Menu>
    );
    return (
      <Dropdown overlay={menu} className="gx-mb-0">
        <Button>...</Button>
      </Dropdown>
    );
  };

  return (
    <Card>
      <Table
        style={{ margin: '-24px' }}
        className="gx-table-responsive"
        columns={columns}
        loading={loading}
        rowClassName={() => 'gx-pointer'}
        onRowClick={handleRowClick}
        dataSource={actsList}
        pagination={false}
      />
    </Card>
  );
}

export default ActTable;
