import { USER_DATE_FORMAT } from '../../utils/constants';
import moment from 'moment';

const waybillStatus = [
  {
    id: 'new',
    title: 'Заказ создан',
    color: 'gray',
  },
  {
    id: 'registered',
    title: 'Заказ создан',
    color: 'gray',
  },
  {
    id: 'pickup_waiting',
    title: ({ pickup_date }: any) => 'Заказ создан' + (pickup_date ? '. Ожидаемая дата сбора '+moment(pickup_date).format(USER_DATE_FORMAT) : ''),
    color: 'blue',
  },
  {
    id: 'delivery_waiting',
    title: ({ delivery_date }: any) => 'Груз в пути' + (delivery_date ? '. Ожидаемая дата доставки '+moment(delivery_date).format(USER_DATE_FORMAT) : ''),
    color: 'yellow2',
  },
  {
    id: 'delivered',
    title: ({ delivery_date }: any) => 'Заказ выполнен' + (delivery_date ? ' '+moment(delivery_date).format(USER_DATE_FORMAT) : ''),
    color: 'green',
  },
  {
    id: 'canceled',
    title: ({ cancel_date }: any) => 'Заказ отменен' + (cancel_date ? ' '+moment(cancel_date).format(USER_DATE_FORMAT) : ''),
    color: 'red',
  },
];


export default waybillStatus;
