import React, { useCallback, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import Modal from 'antd/es/modal';
import Button from 'antd/es/button';
import { Col, Row } from 'antd/es/grid';
import AntInput from '../../../components/AntField/AntInput';


function AddPaymentNumberModal({ onSubmit, onClose }) {
  const form = useRef(null);

  const handleOk = useCallback(
    () => {
      if (form.current) form.current.submitForm();
    },
    [form],
  );

  const handleSubmit = useCallback(
    (values, actions) => {
      if (onSubmit) {
        try {
          onSubmit(values).then(
            () => {
              actions.setSubmitting(false);
            },
            (errors) => {
              console.debug(errors);
              actions.setSubmitting(false);
              actions.setErrors(errors);
            }
          )
        } catch (e) {
          actions.setSubmitting(false);
        }
      }
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
      innerRef={el => form.current = el}
    >
      {({ values, isSubmitting }) => (
        <Modal
          visible
          className="ant-modal_full-width"
          okButtonProps={{ loading: isSubmitting }}
          onOk={handleOk}
          onCancel={onClose}
          footer={
            <Button key="submit" type="primary" loading={isSubmitting} onClick={handleOk}>
              Сохранить
            </Button>
          }
        >
          <Form>
            <Row>
              <Col span={24}>
                <Field
                  label="Номер выплаты"
                  component={AntInput}
                  type="text"
                  name="number"
                  componentClassName="gx-w-100"
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default AddPaymentNumberModal;
