import React, { Component } from 'react';
import { connect } from 'react-redux'
import PaymentTable from '../components/PaymentTable';
import List from '../../../customComponents/List';
import { withQueryParams } from '../../../helpers';
import { loadPayments } from '../action-creators';

const QUERY_PARAMS_CONFIG = {
  page: { type: 'pageNumber' },
}


class PaymentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  setLoading = (loading) => this.setState({ loading });

  componentWillMount() {
    this.loadPayments()
  }

  loadPayments = () => {
    const { params: { page } } = this.props;
    this.setLoading(true);
    this.props.loadPayments(page).then(() => this.setLoading(false), () => this.setLoading(false));
  };

  handlePageChange = (page) => this.props.setFieldValue({ page }, this.loadPayments);

  render () {
    const { params: { page }, paymentsCount } = this.props;
    const { loading } = this.state;
    return (
      <List
        title="Выплаты"
        totalCount={paymentsCount}
        currentPage={page}
        handlePageChange={this.handlePageChange}
        withSearch={false}
      >
        <PaymentTable loading={loading} reload={this.loadPayments} />
      </List>
    )
  }
}

const connectStore = connect(
  ({ payment: { paymentsCount } }) => ({ paymentsCount }),
  { loadPayments },
);

export default connectStore(withQueryParams(QUERY_PARAMS_CONFIG)(PaymentView));
