import {
    Payment,
    SET_PAYMENTS_LIST,
    SET_PAYMENTS_COUNT,
    SET_CURRENT_PAYMENT,
    RESET_CURRENT_PAYMENT,
    SET_CURRENT_PAYMENT_ORDERS_LIST,
    paymentActions,
} from './types';

export interface OrderState {
  paymentsList: Payment[],
  paymentsCount: number,
  currentPayment: Payment | null,
  currentPaymentOrdersList: any,
}

const initialState: OrderState = {
  paymentsList: [],
  paymentsCount: 0,
  currentPayment: null,
  currentPaymentOrdersList: [],
};

export default function (state = initialState, action: paymentActions | any): OrderState {
  switch (action.type) {
    case SET_PAYMENTS_LIST:
      return { ...state, paymentsList: action.payments };
    case SET_PAYMENTS_COUNT:
      return { ...state, paymentsCount: action.count };
    case SET_CURRENT_PAYMENT:
      return { ...state, currentPayment: action.payment };
    case SET_CURRENT_PAYMENT_ORDERS_LIST:
      return { ...state, currentPaymentOrdersList: action.paymentOrders };
    case RESET_CURRENT_PAYMENT:
      return { ...state, currentPayment: initialState.currentPayment };
    default:
      return state;
  }
}
