import {
    seller,
    DeliveryService,
    SET_DELIVERY_SERVICES,
    SET_SELLER_LIST,
    ReferenceActions
} from './types';

export interface ReferenceState {
  deliveryServicesList: Array<DeliveryService>,
  deliveryServices: {[key: string]: DeliveryService},
  sellers: Array<seller>,
}

const initialState: ReferenceState = {
  deliveryServicesList: [],
  deliveryServices: {},
  sellers: []
};


function prepareDeliveryServices(rawDeliveryServices: Array<DeliveryService>): {[key: string]: DeliveryService} {
  const deliveryServices: {[key: string]: DeliveryService} = {};
  if (rawDeliveryServices) {
    for (let i = 0; i < rawDeliveryServices.length; i++) {
      deliveryServices[rawDeliveryServices[i].key] = rawDeliveryServices[i];
    }
  }
  return deliveryServices;
}


export default function (state = initialState, action: ReferenceActions): ReferenceState {
  switch (action.type) {
    case SET_DELIVERY_SERVICES:
      return { ...state, deliveryServicesList: action.deliveryServices, deliveryServices: prepareDeliveryServices(action.deliveryServices) };
    case SET_SELLER_LIST:
      return { ...state, sellers: action.sellers };
    default: return state;
  }
}
