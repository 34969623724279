export const SET_PAYMENTS_LIST = 'SET_PAYMENTS_LIST';
export const SET_PAYMENTS_COUNT = 'SET_PAYMENTS_COUNT';
export const SET_CURRENT_PAYMENT = 'SET_CURRENT_PAYMENT';
export const RESET_CURRENT_PAYMENT = 'RESET_CURRENT_PAYMENT';
export const SET_CURRENT_PAYMENT_ORDERS_LIST = 'SET_CURRENT_PAYMENT_ORDERS_LIST';

export interface Payment {
  paymentId: number,
  accountId: number,
  account: any,
  number: string,
  createdAt: string,
  deliveryService: string,
  plsNumber: string,
  plsOrderNumber: string,
  serviceNumber: string,
  serviceOrderNumber: string,
}


export interface SetPaymentsList {
  type: typeof SET_PAYMENTS_LIST,
  payments: Array<Payment>,
}

export interface SetPaymentsCount {
  type: typeof SET_PAYMENTS_COUNT,
  count: number,
}

export interface SetCurrentPayment {
  type: typeof SET_CURRENT_PAYMENT,
  payment: Payment,
}

export interface ResetCurrentPayment {
  type: typeof RESET_CURRENT_PAYMENT,
}

export interface SetCurrentPaymentOrdersList {
  type: typeof SET_CURRENT_PAYMENT_ORDERS_LIST,
  paymentOrders: any,
}

export type paymentActions = SetPaymentsList | SetPaymentsCount | SetCurrentPayment | ResetCurrentPayment
    | SetCurrentPaymentOrdersList
