export const SET_ORDERS_LIST = 'SET_ORDERS_LIST';
export const SET_ORDERS_COUNT = 'SET_ORDERS_COUNT';
export const RESET_ORDERS_LIST = 'RESET_ORDERS_LIST';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const RESET_CURRENT_ORDER = 'RESET_CURRENT_ORDER';
export const SET_CURRENT_ORDER_TRACKING_LIST = 'SET_CURRENT_ORDER_TRACKING_LIST';
export const SET_CURRENT_ORDER_TRACKING_COUNT = 'SET_CURRENT_ORDER_TRACKING_COUNT';

export interface Tracking {
  id: number,
  description: string,
  date: string,
  createdAt: string,
}


export interface Order {
  id: number,
  createdAt: string,
  deliveryService: string,
  plsNumber: string,
  plsOrderNumber: string,
  serviceNumber: string,
  serviceOrderNumber: string,
}

export interface OrderDetail {
  id: number,
  createdAt: string,
  deliveryService: string,
  plsNumber: string,
  plsOrderNumber: string,
  serviceNumber: string,
  serviceOrderNumber: string,
}

export interface SetOrdersList {
  type: typeof SET_ORDERS_LIST,
  orders: Array<Order>,
}

export interface SetOrdersCount {
  type: typeof SET_ORDERS_COUNT,
  count: number,
}

export interface ResetOrdersList {
  type: typeof RESET_ORDERS_LIST,
}

export interface SetCurrentOrder {
  type: typeof SET_CURRENT_ORDER,
  order: OrderDetail,
}

export interface ResetCurrentOrder {
  type: typeof RESET_CURRENT_ORDER,
}

export interface SetCurrentOrderTrackingList {
  type: typeof SET_CURRENT_ORDER_TRACKING_LIST,
  tracking: Tracking[],
}

export interface SetCurrentOrderTrackingCount {
  type: typeof SET_CURRENT_ORDER_TRACKING_COUNT,
  count: number,
}

export type orderActions = SetOrdersList | SetOrdersCount | ResetOrdersList | SetCurrentOrder |
  ResetCurrentOrder | SetCurrentOrderTrackingList | SetCurrentOrderTrackingCount
