export const SET_ACTS_LIST = 'SET_ACTS_LIST';
export const SET_ACTS_COUNT = 'SET_ACTS_COUNT';
export const SET_CURRENT_ACT = 'SET_CURRENT_ACT';
export const SET_CURRENT_ACT_ORDERS_LIST = 'SET_CURRENT_ACT_ORDERS_LIST';
export const SET_CURRENT_ACT_ORDERS_COUNT = 'SET_CURRENT_ACT_ORDERS_COUNT';

export interface Act {
  id: number,
  sellerId: number,
  amount: number,
  date: string,
}
export interface ActOrder {
  id: number,
}

export interface SetActsList {
  type: typeof SET_ACTS_LIST,
  acts: Array<Act>,
}

export interface SetActsCount {
  type: typeof SET_ACTS_COUNT,
  count: number,
}

export interface SetCurrentAct {
  type: typeof SET_CURRENT_ACT,
  act: Act,
}

export interface SetCurrentActOrdersList {
  type: typeof SET_CURRENT_ACT_ORDERS_LIST,
  orders: ActOrder[],
}

export interface SetCurrentActOrdersCount {
  type: typeof SET_CURRENT_ACT_ORDERS_COUNT,
  count: number,
}

export type orderActions = SetActsList | SetActsCount | SetCurrentAct | SetCurrentActOrdersList
  | SetCurrentActOrdersCount
