import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

function VerticalNav({ onClick }) {
  const menu = [
    {
      title: 'Заказы',
      to: '/orders',
    },
    {
      title: 'Выплаты',
      to: '/payments',
    },
    {
      title: 'Акты',
      to: '/acts',
    },
  ];


  const renderMenu = useCallback(
    (item) => {
      if (item.children && item.children.length) {
        return (
          <>
            <Menu.Item className="ant-menu-item ant-menu-submenu ant-menu-submenu-vertical gx-menu-vertical ant-menu-submenu-vertical">
              <Link className="ant-menu-submenu-title" to={item.to} onClick={onClick}>
                <span>{item.title}</span>
                <i className="ant-menu-submenu-arrow"/>
              </Link>
            </Menu.Item>
            {item.children.map((i) => (
              <div style={{ padding: '0 30px' }}>
                <Menu.Item key={i.to} className="ant-menu-item ant-menu-submenu ant-menu-submenu-vertical gx-menu-vertical">
                  <Link className="ant-menu-submenu-title" to={i.to || '/'} onClick={onClick}>
                    {i.title}
                  </Link>
                </Menu.Item>
              </div>
            ))}
          </>
        )
      }
      return (
        <Menu.Item className="ant-menu-submenu ant-menu-submenu-vertical gx-menu-vertical">
          <Link className="ant-menu-submenu-title" to={item.to} onClick={onClick}>
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
      );
    }, [menu],
  );


  return (
    <Menu
      className="ant-menu-dark ant-menu-light ant-menu-root ant-menu-vertical"
      mode="vertical"
    >
      {menu.map((item) => renderMenu(item))}
    </Menu>


  );
}

export default VerticalNav;
