import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';


const ActDetail = React.lazy(() => import('../components/ActDetail'));
function ActDetailView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ActDetail {...props} />
    </Suspense>
  );
}

export default ActDetailView;
