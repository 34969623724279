import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import MainView from '../views/MainView';
import PrivateRoute from './PrivateRoute';
import OrderView from '../app/order/views/OrderView';
import OrderDetailView from '../app/order/views/OrderDetailView';
import PaymentView from '../app/payment/views/PaymentView';
import PaymentDetailView from '../app/payment/views/PaymentDetailView';
import ActListView from '../app/acts/views/ActListView';
import ActDetailView from '../app/acts/views/ActDetailView';


function AuthorizedRouter() {
  return (
    <MainView>
      <Switch>
        <PrivateRoute exact path="/orders" component={OrderView} />
        <PrivateRoute exact path="/orders/:orderId" component={OrderDetailView} />
        <PrivateRoute exact path="/payments" component={PaymentView} />
        <PrivateRoute exact path="/payments/:paymentId" component={PaymentDetailView} />
        <PrivateRoute exact path="/acts" component={ActListView} />
        <PrivateRoute exact path="/acts/:actId" component={ActDetailView} />
        <Redirect to="/404" />
      </Switch>
    </MainView>
  );
}

export default AuthorizedRouter;
