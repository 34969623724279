import Cookie from 'js-cookie';
import { User, Token } from './types';

export interface UserState {
  user: User | null,
  token: Token | null,
}

const initialState: UserState = {
  user: null,
  token: null,
};

export default function (state = initialState, action: any): UserState {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user };
    case 'SET_TOKEN':
      if (action.remember) {
        localStorage.setItem('refreshToken', action.refreshToken);
      }
      Cookie.set('crmToken', action.accessToken);
      localStorage.setItem('accessToken', action.accessToken);
      localStorage.setItem('tokenExpire', action.expiredAt);

      return {
        ...state,
        token: {
          accessToken: action.accessToken,
          refreshToken: action.refreshToken,
          expiredAt: action.expiredAt,
        },
      };
    default:
      return state;
  }
}
