import { Moment } from 'moment';
import {
  Account,
  AccountBalance,
  AccountFinance,
  UserInvite,
  WaybillAccountStat,
} from './types';

export interface AccountState {
  accountsList: Account[],
  accountsCount: number,
  accountsSearch: string,
  accountsStatus: number | null,
  accountsPage: number,
  accountsCreatedAtFrom: Moment | null,
  accountsCreatedAtTo: Moment | null,
  currentAccount: Account | null,
  accountInvitesList: Array<UserInvite>,
  accountInvitesCount: number,
  breadcrumbAccount: Account | null,
  currentAccountBalance: AccountBalance | null,
  currentAccountFinance: AccountFinance | null,
  waybillStat: Array<WaybillAccountStat>,
  currentStatTotalCount: number,
  currentStatTotalAmount: number,
  manualBonusList: Array<any>,
  manualBonusCount: number,
}

const initialState: AccountState = {
  accountsList: [],
  accountsCount: 0,
  accountsSearch: '',
  accountsStatus: null,
  accountsPage: 1,
  accountsCreatedAtFrom: null,
  accountsCreatedAtTo: null,
  currentAccount: null,
  accountInvitesList: [],
  accountInvitesCount: 0,
  breadcrumbAccount: null,
  currentAccountBalance: null,
  currentAccountFinance: null,
  waybillStat: [],
  currentStatTotalCount: 0,
  currentStatTotalAmount: 0,
  manualBonusList: [],
  manualBonusCount: 0,
};

export default function (state = initialState, action: any): AccountState {
  switch (action.type) {
    case 'SET_ACCOUNTS_LIST':
      return { ...state, accountsList: action.accounts };
    case 'SET_ACCOUNTS_COUNT':
      return { ...state, accountsCount: action.count };
    case 'SET_ACCOUNTS_SEARCH':
      return { ...state, accountsSearch: action.search };
    case 'SET_ACCOUNTS_STATUS':
      return { ...state, accountsStatus: action.status };
    case 'SET_CURRENT_ACCOUNT':
      return { ...state, currentAccount: action.account };
    case 'SET_ACCOUNTS_PAGE':
      return { ...state, accountsPage: action.page };
    case 'SET_ACCOUNTS_CREATED_AT_FROM':
      return { ...state, accountsCreatedAtFrom: action.createdAtFrom };
    case 'SET_ACCOUNTS_CREATED_AT_TO':
      return { ...state, accountsCreatedAtTo: action.createdAtTo };
    case 'SET_ACCOUNT_INVITES_LIST':
      return { ...state, accountInvitesList: action.invites };
    case 'SET_ACCOUNT_WAYBILL_STAT':
      return {
        ...state, waybillStat: action.series, currentStatTotalCount: action.totalCount, currentStatTotalAmount: action.totalAmount,
      };
    case 'SET_ACCOUNT_INVITES_COUNT':
      return { ...state, accountInvitesCount: action.count };
    case 'SET_BREADCRUMB_ACCOUNT':
      return { ...state, breadcrumbAccount: action.account };
    case 'SET_CURRENT_ACCOUNT_BALANCE':
      return { ...state, currentAccountBalance: action.balance };
    case 'RESET_CURRENT_ACCOUNT_BALANCE':
      return { ...state, currentAccountBalance: initialState.currentAccountBalance };
    case 'SET_CURRENT_ACCOUNT_FINANCE':
      return { ...state, currentAccountFinance: action.finance };
    case 'RESET_CURRENT_ACCOUNT_FINANCE':
      return { ...state, currentAccountFinance: initialState.currentAccountFinance };

    case 'SET_MANUAL_BONUS_LIST':
      return { ...state, manualBonusList: action.manualBonusList };
    case 'SET_MANUAL_BONUS_COUNT':
      return { ...state, manualBonusCount: action.manualBonusCount };
    default:
      return state;
  }
}
