import {
    seller,
    DeliveryService,
    SET_DELIVERY_SERVICES,
    SET_SELLER_LIST,
    SetDeliveryServices,
    SetSellerList
} from './types';
import { ThunkAction } from '../../utils/types';


export const setDeliveryServices = (deliveryServices: Array<DeliveryService>): SetDeliveryServices => ({
  type: SET_DELIVERY_SERVICES,
  deliveryServices,
});

export const setSellerList = (sellers: Array<seller>): SetSellerList => ({
  type: SET_SELLER_LIST,
  sellers,
});

export const loadDeliveryServices = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/rates/delivery_services/').then(
  (response: {deliveryServices: Array<DeliveryService>}) => {
    dispatch(setDeliveryServices(response.deliveryServices));
    return Promise.resolve(response);
  },
);

export const getSellerList = (): ThunkAction => (dispatch, getState, http) =>
  http.get('/api/v1/contracts/sellers/').then(
    ({ list }: { list: Array<seller> }) => {
      dispatch(setSellerList(list));
      return Promise.resolve();
    },
  );

