import {
  Act,
  ActOrder,
  SET_ACTS_LIST,
  SET_ACTS_COUNT,
  SET_CURRENT_ACT,
  SET_CURRENT_ACT_ORDERS_LIST,
  SET_CURRENT_ACT_ORDERS_COUNT,
  SetActsList,
  SetActsCount,
  SetCurrentAct,
  SetCurrentActOrdersList,
  SetCurrentActOrdersCount,
} from './types';
import { ThunkAction } from '../../utils/types';


export const setActsList = (acts: Array<Act>): SetActsList => ({
  type: SET_ACTS_LIST,
  acts,
});


export const setActsCount = (count: number): SetActsCount => ({
  type: SET_ACTS_COUNT,
  count,
});

export const setCurrentAct = (act: Act): SetCurrentAct => ({
  type: SET_CURRENT_ACT,
  act,
});

export const setCurrentActOrderList = (orders: ActOrder[]): SetCurrentActOrdersList => ({
  type: SET_CURRENT_ACT_ORDERS_LIST,
  orders,
});

export const setCurrentActOrderCount = (count: number): SetCurrentActOrdersCount => ({
  type: SET_CURRENT_ACT_ORDERS_COUNT,
  count,
});


export const loadActs = (page = 1): ThunkAction => (dispatch, getState, http) => {
  const params: any = { limit: 25, skip: page * 25 - 25 };
  return http.get('/api/v1/shop/act/list/', params).then(
    (response: any) => {
      const { items, count } = response;
      dispatch(setActsList(items || []));
      dispatch(setActsCount(count));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadAct = (actId: number): ThunkAction =>
  (dispatch, getState, http) => {
    return http.get(`/api/v1/shop/act/${actId}/detail/`).then(
      (response: Act) => {
        dispatch(setCurrentAct(response));
        return Promise.resolve(response);
      },
      (reject: any) => Promise.reject(reject),
    );
  };

export const loadActOrderList = (actId: number, page=1): ThunkAction =>
  (dispatch, getState, http) => {
    return http.get(`/api/v1/shop/act/order/list/`, { page, act_id: actId }).then(
      (response: any) => {
        const { items, count } = response;
        dispatch(setCurrentActOrderList(items));
        dispatch(setCurrentActOrderCount(count));
        return Promise.resolve(response);
      },
      (reject: any) => Promise.reject(reject),
    );
  };
