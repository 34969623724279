import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Row, Table } from 'antd';
import { useHistory } from 'react-router';
import { WAYBILL_STATUSES } from '../../../utils/constants';
import waybillStatus from '../constants';

function getNumber(record) {
  let statusData = null;
  if (WAYBILL_STATUSES.hasOwnProperty(record.state)) statusData = WAYBILL_STATUSES[record.state];

  return (
    <Row>
      <Col span={24}>
        <span className="gx-nonhover gx-mr-2 gx-pr-1">
          <i className={`icon icon-circle gx-fs-sm gx-text-${statusData ? statusData.color : 'gray'}`} />
        </span>
        {record.serviceNumber ? record.serviceNumber : record.plsNumber}
      </Col>
      <Col span={24}>
        <span className="gx-nonhover gx-mr-3 gx-pr-2" />
        {record.serviceOrderNumber ? record.serviceOrderNumber : ''}
      </Col>
    </Row>
  );
}

function OrderTable({ loading }) {
  const history = useHistory();
  const { ordersList } = useSelector((state) => state.order);

  const getStatus = (order) => {
    const statusItem = waybillStatus.filter((itemWaybillStatus) => order.state === itemWaybillStatus.id)[0];
    if (statusItem) {
      return typeof statusItem.title === 'function' ?
        (order.stateData ? statusItem.title(JSON.parse(order.stateData)) : statusItem.title({})) :
        statusItem.title;
    }
  }

  const handleRowClick = useCallback((record) => {
    history.push(`/orders/${record.id}`);
  }, []);

  const columns = [
    {
      className: 'gx-text-left',
      title: 'Номер накладной и вызова курьера',
      dataIndex: 'bill',
      key: 'bill',
      render: (text, record) => getNumber(record),
    },
    {
      title: 'Статус',
      key: 'status',
      render: (text, record) =>
        <>
          {getStatus(record)}
        </>,
    },
    {
      className: 'gx-text-left',
      title: 'Сбор',
      dataIndex: 'pickup',
      key: 'pickup',
      render: (text, record) => <span>{record.record?.senderCity}<br />{record.account.title}</span>,
    },
    {
      className: 'gx-text-left',
      title: 'Доставка',
      dataIndex: 'delivery',
      key: 'delivery',
      render: (text, record) => <span>{record.record?.receiverCity}</span>,
    },
    {
      className: 'gx-text-left',
      title: 'Доставка (руб)',
      dataIndex: 'deliveryPrice',
      key: 'deliveryPrice',
      render: (text, record) => <span>{record.record?.price}</span>,
    },
    {
      className: 'gx-text-left',
      title: 'Товары (руб)',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
  ];

  return (
    <Card>
      <Table
        style={{ margin: '-24px' }}
        className="gx-table-responsive"
        columns={columns}
        rowClassName={() => 'gx-pointer'}
        onRowClick={handleRowClick}
        loading={loading}
        dataSource={ordersList}
        pagination={false}
      />
    </Card>
  );
}

export default OrderTable;
