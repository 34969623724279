import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import users from './app/users/reducer';
import reference from './app/reference/reducer';
import accounts from './app/accounts/reducer';
import order from './app/order/reducer';
import payment from './app/payment/reducer';
import acts from './app/acts/reducer';


const history = createBrowserHistory();

const rootReducer = combineReducers({
  users,
  reference,
  accounts,
  order,
  payment,
  acts,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;
export { history };
