import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, Table, Button } from 'antd';
import useDispatch from 'utils/hooks/useDispatch';
import { USER_DATE_FORMAT } from 'utils/constants';
import { setPaymentNumber } from '../action-creators';
import AddPaymentNumberModal from './AddPaymentNumberModal';


function PaymentTable({ loading, reload }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { paymentsList } = useSelector((state) => state.payment);
  const [ editPaymentId, setEditPaymentId ] = useState(null);


  const handleRowClick = useCallback((record) => {
    history.push(`/payments/${record.paymentId}`);
  }, []);

  const addPaymentNumber = (paymentId, { number }) => {
    dispatch(setPaymentNumber(paymentId, number)).then(
      (response) => {
        setEditPaymentId(null);
        reload();
        return Promise.resolve(response);
      },
      (response) => Promise.reject(response)
    )
    setEditPaymentId(paymentId);
    return false;
  }

  const showAddPaymentNumberModal = (e, paymentId) => {
    e.stopPropagation();
    setEditPaymentId(paymentId);
    return false;
  }

  const columns = [
    {
      title: 'Номер выплаты',
      dataIndex: 'number',
      key: 'number',
      render: (text) => <div>
        <span className="gx-nonhover gx-mr-2 gx-pr-1">
          <i className={`icon icon-circle gx-fs-sm ${text ? 'gx-text-green' : ''}`} />
        </span>
        {text}
      </div>
    },
    {
      title: 'Клиент',
      dataIndex: 'accountId',
      key: 'accountId',
      render: (text, record) => <span>{record.account?.title}</span>
    },
    {
      title: 'Плановая дата выплаты',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{moment(text).format(USER_DATE_FORMAT)}</span>
    },
    {
      title: 'Сумма (руб)',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: '',
      dataIndex: 'number',
      key: 'number',
      render: (text, record) => text ?
        'Оплачено' :
        <Button onClick={(e) => showAddPaymentNumberModal(e, record.paymentId)} className="gx-mb-0">Оплатить</Button>,
      width: 120,
    },
  ];

  return (
    <Card>
      {editPaymentId && (
          <AddPaymentNumberModal
            onClose={() => setEditPaymentId(null)}
            onSubmit={(data) => addPaymentNumber(editPaymentId, data)}
          />
      )}
      <Table
        style={{ margin: '-24px' }}
        className="gx-table-responsive"
        columns={columns}
        loading={loading}
        rowClassName={() => 'gx-pointer'}
        onRowClick={handleRowClick}
        dataSource={paymentsList}
        pagination={false}
      />
    </Card>
  );
}

export default PaymentTable;
