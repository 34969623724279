export const USER_DATE_FORMAT = 'DD.MM.YYYY';
export const RENDER_DATE_FORMAT = 'DD.MM.YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const CLIENT_TIME_FORMAT = 'HH:mm';
export const EXPANDED_CLIENT_DATE_FORMAT = 'DD MMMM YYYY';

export const WAYBILL_STATUSES: {[key: string]: any} = {
  new: {
    title: 'Новый',
    color: 'blue',
  },
  registered: {
    title: 'Зарегистрированный',
    color: 'gray',
  },
  pickup_waiting: {
    title: 'Ожидает сбора',
    color: 'blue',
  },
  delivery_waiting: {
    title: 'Ожидает доставки',
    color: 'yellow2',
  },
  delivered: {
    title: 'Выполненный',
    color: 'green',
  },
  canceled: {
    title: 'Отмененный',
    color: 'red',
  },
};
