import {
    Order,
    OrderDetail,
    Tracking,
    SET_ORDERS_LIST,
    SET_ORDERS_COUNT,
    RESET_ORDERS_LIST,
    SET_CURRENT_ORDER,
    RESET_CURRENT_ORDER,
    SET_CURRENT_ORDER_TRACKING_LIST,
    SET_CURRENT_ORDER_TRACKING_COUNT,
    orderActions,
} from './types';

export interface OrderState {
  ordersList: Order[],
  ordersCount: number,
  currentOrder: OrderDetail | null,
  currentOrderTrackingList: Tracking[],
  currentOrderTrackingCount: number,
}

const initialState: OrderState = {
  ordersList: [],
  ordersCount: 0,
  currentOrder: null,
  currentOrderTrackingList: [],
  currentOrderTrackingCount: 0,
};

export default function (state = initialState, action: orderActions | any): OrderState {
  switch (action.type) {
    case SET_ORDERS_LIST:
      return { ...state, ordersList: action.orders };
    case SET_ORDERS_COUNT:
      return { ...state, ordersCount: action.count };
    case RESET_ORDERS_LIST:
      return { ...state, ordersList: initialState.ordersList, ordersCount: initialState.ordersCount };
    case SET_CURRENT_ORDER:
      return { ...state, currentOrder: action.order };
    case RESET_CURRENT_ORDER:
      return { ...state, currentOrder: initialState.currentOrder };
    case SET_CURRENT_ORDER_TRACKING_LIST:
      return { ...state, currentOrderTrackingList: action.tracking };
    case SET_CURRENT_ORDER_TRACKING_COUNT:
      return { ...state, currentOrderTrackingCount: action.count };
    default:
      return state;
  }
}
